import React, { Component } from 'react';
import styled from '@emotion/styled';
import p5 from 'p5';

const BackgroundSketchWrapper = styled.div`
    & > canvas {
        position: absolute;
        display: block;
        margin: 0 auto;
        padding: 0 auto;
        top: 0;
        left: 0;
        z-index: -2;
    }
`;

class BackgroundSketch extends Component {
    constructor(props) {
        super(props);
        this.sketchRef = React.createRef();
        this.canvas = null;
    }

    componentDidMount() {
        this.canvas = new p5(this.props.sketch, this.sketchRef.current); //eslint-disable-line
    }

    componentDidUpdate() {
        this.canvas.remove();
        this.canvas = new p5(this.props.sketch, this.sketchRef.current); //eslint-disable-line
    }

    componentWillUnmount() {
        this.canvas.remove();
    }

    render() {
        return <BackgroundSketchWrapper ref={this.sketchRef} />;
    }
}

export default BackgroundSketch;
