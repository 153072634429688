import React from 'react';
import Layout from '../components/layout';
import BackgroundSketch from '../components/backgroundSketch';
// import Logo from '../components/logo';
// import Social from '../components/social';

import fallingBox from '../scripts/hp/fallingBox';

const IndexPage = () => {
    return (
        <Layout>
            <BackgroundSketch sketch={fallingBox} />
        </Layout>
    );
};

export default IndexPage;
