import React from 'react';
import '../global-styles/index.scss';
import PropTypes from 'prop-types';
import { css, Global } from '@emotion/core';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import Header from './header';
import Footer from './footer';
import 'katex/dist/katex.min.css';

const StyledLayout = styled.div`
    margin: 0 auto;
    padding: 0 auto;
    /* box-shadow: inset 0 0 160px rgba(0, 0, 0, 0.5); */
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    min-height: 100vh;
    /* width: 100%; */
    /* max-width: 700px; */
`;

const Content = styled.div`
    flex-grow: 1;
    padding: 0;
    /* text-align: justify;
    text-justify: inter-word; */
    /* align-items: center; */
    /* justify-content: center; */
    margin-left: auto;
    margin-right: auto;
    width: 87.5%;
    padding-left: 12.5%;
    max-width: 1400px;
    @media (max-width: 760px) {
        width: 100%;
        padding-left: 0;
    }
    /* margin: 0 auto; */
    /* padding: 0 auto; */
`;

const Article = styled.div`
    flex-grow: 1;
    padding: 0;
    text-align: justify;
    text-justify: inter-word;
    /* align-items: center; */
    /* justify-content: center; */
    /* margin-left: auto;
    margin-right: auto; */
    width: 55%;
    @media (max-width: 760px) {
        width: 100%;x
    }
    /* padding-left: 12.5%; */
    /* max-width: 1400px; */
    /* margin: 0 auto; */
    /* padding: 0 auto; */
`;

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                }
            }
        }
    `);
    return (
        <StyledLayout>
            <Helmet
                title={data.site.siteMetadata.title}
                meta={[{ name: 'description', content: data.site.siteMetadata.description }]}
            />
            <Container>
                <Header />
                <Content>
                    <Article>{children}</Article>
                </Content>
                <Footer />
            </Container>
        </StyledLayout>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout;
