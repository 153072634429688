// eslint no-param-reassign: "off", no-use-before-define: "off"
// import 'p5/lib/addons/p5.dom';

export default function sketch(p) {
    // Libraries
    const glMatrix = require('gl-matrix');
    // const Dw = require('./p5.easycam.js');

    // Consts
    const numBoxes = 7;
    const numPhaseSteps = 30;
    const endRotation = 180;

    // Init let vars
    let repaintBackground = true;
    let drawStroke = true;
    let move = true;
    let upXdown = 1;
    let useDefaultMouseX = true;
    let step = 0;
    let savedStep = 0;
    let rotateAngle;
    let boxLength;
    let shiftVector;
    let minDistance;
    let maxDistance;
    let shift;
    let lightDirection;
    let standardShiftVector;
    let halfBoxLength;
    let rotateDirection;
    let rotateDirection2;
    let cam;
    let mouseXratio = 0.33;
    let intro = true;
    let tumbleLeft = true;
    let savedTumble;
    let introRow = -4;
    let introCol = -1;
    let introRow2 = 1;
    let introCol2 = 1;
    let collapsing = false;
    let collapsed = false;
    let expanding = false;
    const minBoxLength = 55;
    // let bgButton;
    // let bgButtonX = p.windowWidth / 2;
    // let bgButtonY = p.windowHeight / 2;

    // Colors
    p.colorMode(p.RGB, 255);
    let bauhausWhite = p.color(209, 208, 166);
    // let bauhausWhite = p.color(209, 208, 188);
    let bauhausRed = p.color(173, 39, 40);
    const directionalLightColor = p.color(255, 255, 255);

    const [bwh, bws, bwb] = [
        p.hue(bauhausWhite),
        p.saturation(bauhausWhite),
        p.lightness(bauhausWhite)
    ];
    // console.log("bauhausWhite", bwh, bws, bwb);
    const [brh, brs, brb] = [p.hue(bauhausRed), p.saturation(bauhausRed), p.lightness(bauhausRed)];
    // p.colorMode(p.HSL, 360, 100, 100, 1);
    p.colorMode(p.HSL);
    bauhausWhite = p.color(bwh, bws, bwb);
    bauhausRed = p.color(brh, brs, brb, 1);
    const movingBoxColor = bauhausRed;
    // const backgroundColor = 10;
    // const backgroundColor = 255;
    const backgroundColor = 0;

    p.mouseMoved = () => {
        useDefaultMouseX = false;
    };

    p.windowResized = () => {
        p.resizeCanvas(p.windowWidth, p.windowHeight);
        cam.camera(0, -0.33 * p.windowHeight, 0.66 * p.windowHeight, 0, 0, 0, 0, 1, 0);
        const maxHeightWidth = Math.max(p.windowWidth, p.windowHeight);
        boxLength = Math.max(Math.floor(maxHeightWidth / (2 * (numBoxes + 2))), minBoxLength);
        halfBoxLength = boxLength / 2;
        standardShiftVector = p.createVector(halfBoxLength, -halfBoxLength, halfBoxLength);
        [minDistance, maxDistance] = calcMinMaxDistance(numBoxes, boxLength);
        lightDirection = p.createVector(-1, 1, -1);
        lightDirection.normalize();
    };

    p.setup = () => {
        p.createCanvas(p.windowWidth, p.windowHeight, p.WEBGL);
        p.frameRate(36);
        // p.frameRate(10);
        // let gl = p._renderer.GL;
        // gl.disable(gl.DEPTH_TEST);
        cam = p.createCamera();
        cam.camera(0, -0.33 * p.windowHeight, 0.66 * p.windowHeight, 0, 0, 0, 0, 1, 0);
        const maxHeightWidth = Math.max(p.windowWidth, p.windowHeight);
        boxLength = Math.max(Math.floor(maxHeightWidth / (2 * (numBoxes + 2))), minBoxLength); 
        // boxLength = Math.floor(maxHeightWidth / (2 * (8 + 2)));
        halfBoxLength = boxLength / 2;
        standardShiftVector = p.createVector(halfBoxLength, -halfBoxLength, halfBoxLength);
        [minDistance, maxDistance] = calcMinMaxDistance(numBoxes, boxLength);
        lightDirection = p.createVector(-1, 1, -1);
        lightDirection.normalize();
        p.angleMode(p.DEGREES); // angleMode(RADIANS);
        p.smooth();
        // bgButton = p.createButton("click me!");
        // bgButton.mousePressed(backgroundAction);
        // bgButton.position(p.windowWidth - 100, p.windowHeight - 50);
        // bgButton.class("bgButtonInvis");
    };

    p.draw = () => {
        // console.log(repaintBackground);
        if (move) {
            // drawAxes();
            let gLmovingBoxPosition = glMatrix.vec3.fromValues(
                halfBoxLength,
                -halfBoxLength,
                halfBoxLength
            );
            if (repaintBackground) {
                p.background(backgroundColor); // p.mouseY / p.windowHeight
            }
            p.directionalLight(directionalLightColor, lightDirection);
            p.rotateY(135);
            const easedStepFraction = ease(p.map(step, 0, numPhaseSteps, 0, 1));
            if (upXdown === 1) {
                shift = p.map(easedStepFraction, 0, 1, 0, boxLength); // upXdown *
                rotateAngle = p.map(easedStepFraction, 0, 1, 0, endRotation);
            } else {
                shift = p.map(easedStepFraction, 0, 1, boxLength, 0); // upXdown *
                rotateAngle = p.map(easedStepFraction, 0, 1, endRotation, 0);
            }
            if (tumbleLeft) {
                gLmovingBoxPosition = glMatrix.vec3.rotateZ(
                    glMatrix.vec3.create(),
                    gLmovingBoxPosition,
                    glMatrix.vec3.create(),
                    -p.radians(rotateAngle)
                );
                rotateDirection = p.createVector(0, 0, -1);
                rotateDirection2 = p.createVector(1, 0, 0);
                shiftVector = p.createVector(shift, -shift, 0);
            } else {
                gLmovingBoxPosition = glMatrix.vec3.rotateX(
                    glMatrix.vec3.create(),
                    gLmovingBoxPosition,
                    glMatrix.vec3.create(),
                    p.radians(rotateAngle)
                );
                rotateDirection = p.createVector(1, 0, 0);
                rotateDirection2 = p.createVector(0, 0, -1);
                shiftVector = p.createVector(0, -shift, shift);
            }

            // Translate everything (create the falling effect!)
            if (!intro) p.translate(shiftVector); // .mult(upXdown)

            drawBaseBoxes(numBoxes, shiftVector, gLmovingBoxPosition);

            if (!collapsing && !collapsed && !expanding) {
                drawMovingBox(introCol, introRow, rotateDirection);
                if (intro) drawMovingBox(introCol2, introRow2, rotateDirection2);
            }
            // Check whether direction has to be changed.
            if (
                (upXdown === 1 && rotateAngle === endRotation && !collapsing && !collapsed) ||
                (upXdown === -1 && rotateAngle === 0)
            ) {
                rotateAngle = 0;
                step = 0;
                tumbleLeft = !tumbleLeft;
                if (intro) {
                    introRow += 1;
                    introRow2 += 1;
                    if ((introRow > 0 && !tumbleLeft) || (introRow > 0 && tumbleLeft)) {
                        intro = false;
                        // bgButton.class("bgButton");
                        // bgButton.position(p.windowWidth - 100, p.windowHeight - 50);
                    }
                    if (tumbleLeft) introCol += 1;
                    if (!tumbleLeft) introCol2 += 1;
                } else {
                    if (tumbleLeft) introCol2 += 2;
                    if (!tumbleLeft) introCol2 += 2;
                }
            }
            step += 1;
        }
        // console.log("collapsed: " + collapsed + " collapsing: " + collapsing + " expanding: " + expanding);
    };

    function drawBox(translateVector, materialColor) {
        p.push();
        p.translate(translateVector.add(standardShiftVector));
        p.ambientMaterial(materialColor);
        p.box(boxLength);
        p.pop();
    }

    function drawMovingBox(introCol, introRow, rotateDirection) {
        if (repaintBackground) {
            p.noStroke();
            p.push(); // Draw falling box
            if (intro) {
                p.translate(
                    p.createVector(
                        (introCol - introRow) * boxLength,
                        introRow * boxLength - boxLength,
                        -(introCol - 1) * boxLength
                    )
                );
            }
            // drawAxes();
            p.rotate(rotateAngle, rotateDirection); // upXdown *
            drawBox(p.createVector(0, 0, 0), movingBoxColor);
            // drawBox(p.createVector(0, 0, 0), movingBoxColor);
            p.pop(); // Draw background boxes
        }
    }

    function drawBaseBoxes(numBoxes, shiftVector, gLmovingBoxPosition) {
        const movingBoxPosition = p.createVector(
            gLmovingBoxPosition[0],
            gLmovingBoxPosition[1],
            gLmovingBoxPosition[2]
        );
        if (useDefaultMouseX) {
            mouseXratio = 0.33;
        } else {
            mouseXratio = p.mouseX / p.windowWidth;
        }
        const mouseYratio = p.mouseY / p.windowHeight;
        for (let rowIx = -numBoxes; rowIx <= numBoxes; rowIx += 1) {
            const row = rowIx + 1;
            const offset = Math.floor((row - 1) / 2);
            for (let colIx = -numBoxes; colIx <= numBoxes; colIx += 1) {
                const col = colIx + offset + 1;
                let v;
                if (collapsing || collapsed) {
                    const expstep = Math.min(step ** 1.3, boxLength);
                    const yplode = expstep * row;
                    const xplode = expstep * (col - row);
                    const zplode = expstep * -(col - 1);
                    v = p.createVector(
                        (col - row) * boxLength - xplode, //  - 0.5 * xplode - step,
                        row * boxLength - yplode, // + 10 * colIx,
                        -(col - 1) * boxLength - zplode
                    );
                    if (step ** 1.3 >= boxLength) {
                        // step = 0;
                        // move = false;
                        collapsed = true;
                        collapsing = false;
                    }
                } else if (expanding) {
                    const expstep = Math.max(0, boxLength - step ** 1.3);
                    const yplode = expstep * row;
                    const xplode = expstep * (col - row);
                    const zplode = expstep * -(col - 1);
                    v = p.createVector(
                        (col - row) * boxLength - xplode, //  - 0.5 * xplode - step,
                        row * boxLength - yplode, // + 10 * colIx,
                        -(col - 1) * boxLength - zplode
                    );
                    if (boxLength - step ** 1.3 <= 0) {
                        // move = false;
                        expanding = false;
                        // collapsing = false;
                    }
                } else {
                    // Normal non exploding behaviour.
                    v = p.createVector(
                        (col - row) * boxLength,
                        row * boxLength, // + 10 * colIx,
                        -(col - 1) * boxLength
                    );
                }
                const distFromMoving = v.dist(movingBoxPosition);

                // let distFactor = 2 * p.mouseX/p.windowWidth * p.map(distFromMoving, minDistance, maxDistance, 20, 0);
                const distFactor =
                    2 * mouseXratio * p.map(distFromMoving, minDistance, maxDistance, 20, 0);
                // let p.map(distFromMoving, minDistance, maxDistance, 0, 1);
                // let matColor = p.max(p.min(160 - (distFactor * 230), 255), 0)
                const matColor = p.color(bwh, bws, distFactor, 1); // matColorAlpha //  p.mouseY / p.windowHeight*2
                if (drawStroke) {
                    p.stroke((mouseYratio * 255) / 4, 1); // matColorAlpha
                }
                drawBox(v, matColor);
            }
        }
    }

    p.keyTyped = () => {
        if (p.key === 'b') {
            backgroundAction();
        } else if (p.key === 's') {
            drawStroke = !drawStroke;
        } else if (p.key === 'm') {
            move = !move;
        } else if (p.key === 'r') {
            upXdown = upXdown === 1 ? -1 : 1;
        } else if (p.key === 'e') {
            changeExplosion();
        }
    };

    function backgroundAction() {
        if (!repaintBackground) {
            intro = true;
            step = savedStep;
            tumbleLeft = savedTumble;
            if (tumbleLeft) {
                introCol = -1;
            } else {
                introCol = -2;
            }
            introRow = -4;
            introRow2 = 1;
            introCol2 = 1;
            repaintBackground = !repaintBackground;
            // bgButton.class("bgButtonInvis");
        } else if (!intro) {
            savedStep = step;
            savedTumble = !tumbleLeft;
            repaintBackground = !repaintBackground;
            // bgButton.position(p.windowWidth - 100, 20);
        }
    }

    function changeExplosion() {
        step = 0;
        if (collapsed) {
            collapsed = false;
            expanding = true;
        } else {
            collapsing = true;
        }
        // collapsing = !collapsing;
    }

    p.touchMoved = () => {
        // prevent default
        return false;
    };

    // p.mousePressed = () => {
    //     if (!repaintBackground) {
    //         intro = true;
    //         step = savedStep;
    //         tumbleLeft = savedTumble;
    //         if (tumbleLeft) {
    //             introCol = -1;
    //         } else {
    //             introCol = -2;
    //         }
    //         introRow = -4;
    //         introRow2 = 1;
    //         introCol2 = 1;
    //         repaintBackground = !repaintBackground;
    //     } else if (!intro) {
    //         savedStep = step;
    //         savedTumble = !tumbleLeft;
    //         repaintBackground = !repaintBackground;
    //     }
    // };

    function ease(x) {
        return x * x * x;
    }

    // function preload() {
    //     f = loadFont(
    //         "https://cdnjs.cloudflare.com/ajax/libs/topcoat/0.8.0/font/SourceCodePro-Bold.otf"
    //     );
    // }

    function drawAxes() {
        // x = red, y = green, z = blue
        p.colorMode(p.RGB);
        p.stroke(255, 0, 0);
        p.line(0, 0, 0, -100, 0, 0);
        p.stroke(0, 255, 0);
        p.line(0, 0, 0, 0, -100, 0);
        p.stroke(0, 0, 255);
        p.line(0, 0, 0, 0, 0, -100);
        p.noStroke();
        p.colorMode(p.HSL);
    }

    function calcMinMaxDistance(numBoxes, boxLength) {
        let maxi = 0;
        let mini = 99999999999999;
        let magn = 0;
        for (let row = -numBoxes + 1; row < numBoxes + 2; row++) {
            const offset = Math.floor((row - 1) / 2);
            for (let i = -numBoxes + offset + 1; i < numBoxes + 2 + offset; i++) {
                const v = p.createVector(
                    (i - row) * boxLength,
                    row * boxLength,
                    -(i - 1) * boxLength
                );
                magn = v.mag();
                if (magn < mini) {
                    mini = magn;
                }
                // print(magn)
                if (magn > maxi) {
                    maxi = magn;
                }
            }
        }
        return [mini, maxi];
    }
}
